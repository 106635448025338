import { createMuiTheme } from "@material-ui/core/styles";


const theme = createMuiTheme({
    palette: {
      primary: {
	      main: '#0098a1',
	    },
      secondary: {
        main: '#757575',
      },
    },
});
  
export default theme;
